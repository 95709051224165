import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { media } from 'src/utils/style-helper'

const Container = styled.div`
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;

  & h1,
  h2 {
    font-family: ${(props) => props.theme.fonts.athletics.medium};
    margin: 30px auto 20px auto;
  }

  & h3,
  h4,
  h5,
  h6 {
    font-family: ${(props) => props.theme.fonts.athletics.medium};
    margin: 50px auto 20px auto;
  }

  & h1 {
    ${media.mobile`
      font-size: 46px;
    `}
    ${media.desktop`
      font-size: 54px;
    `}
  }

  & h2 {
    ${media.mobile`
      font-size: 36px;
    `}
    ${media.desktop`
      font-size: 42px;
    `}
  }

  & h3 {
    ${media.mobile`
      font-size: 20px;
    `}
    ${media.desktop`
      font-size: 24px;
    `}
  }

  & h4,
  h5,
  h6 {
    ${media.mobile`
      font-size: 16px;
    `}
    ${media.tablet`
      font-size: 24px;
    `}
    ${media.desktop`
      font-size: 24px;
    `}
  }

  & p {
    margin: 0 auto 20px auto;
    ${media.mobile`
      font-size: 14px;
      line-height: 28px;
    `}
    ${media.tablet`
      font-size: 16px;
      line-height: 30px;
    `}
  }

  & ol,
  ul {
    margin: 10px auto 40px auto;
  }

  & li,
  blockquote {
    font-family: ${(props) => props.theme.fonts.larsseit.light};
    color: ${(props) => props.theme.colors.navy};
  }

  & blockquote {
    margin: 40px 0 30px 30px;
    color: ${(props) => props.theme.colors.slate};
    font-family: ${(props) => props.theme.fonts.larsseit.regular};
    & > p {
      line-height: 1.4 !important;
      font-style: italic;
      &:before {
        content: '“';
        position: absolute;
        font-family: ${(props) => props.theme.fonts.larsseit.bold};
        color: ${(props) => props.theme.colors.lightGrey};
      }
    }
  }

  & li {
    padding: 0 0 10px 12px;
    font-size: 15px;
    line-height: 26px;
  }

  & a {
    font-family: ${(props) => props.theme.fonts.larsseit.medium};
    color: ${(props) => props.theme.colors.orange};
    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    font-family: ${(props) => props.theme.fonts.larsseit.bold};
    font-weight: 400;
    & a {
      font-family: ${(props) => props.theme.fonts.larsseit.bold};
    }
  }

  & ol li p img {
    min-width: 80% !important;
    margin: 0 auto;
  }

  ${media.mobile`
    & p > img {
      margin: 20px 0;
    }
    & blockquote {
      font-size: 0.9rem;
      padding-left: 10px;
      & > p {
        padding-right: 20px;
        &:before {
          margin: -30px 0 0 -52px;
          font-size: 70px;
        }
      }
    }
  `}
  ${media.tablet`
    & p > img {
      margin: 20px 0;
      min-width: 110% !important;
      margin-left: -5%;
    }
  `}
  ${media.desktop`
    & p > img {
      margin: 40px 0;
      min-width: 114% !important;
      margin-left: -7%; 
    }
    & blockquote {
      padding-left: 20px;
      & > p {
        padding-right: 50px;
        &:before {
          margin: -35px 0 0 -95px;
          font-size: 120px;
        }
      }
    }
  `}
`

export const HTMLContent = ({ content, className }) => (
  <Container className={className} dangerouslySetInnerHTML={{ __html: content }} />
)

const Content = ({ content, className }) => <Container className={className}>{content}</Container>

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
