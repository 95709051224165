import React from 'react'
import styled from 'styled-components'

import SmallBlogCard from 'src/components/shared/SmallBlogCard'
import { H1, InternalLink } from 'src/components/shared/text'
import { SecondaryButton } from 'src/components/shared/buttons'
import { media } from 'src/utils/style-helper'

const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  display: flex;
  border-top: 1px solid ${(props) => props.theme.colors.lightText};
  ${media.mobile`
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
  `}
  ${media.tablet`
    padding: 60px 30px 80px 30px;
  `}
  ${media.desktop`
    flex-direction: row;
    align-items: flex-start;
    max-width: 1400px;
    padding: 80px 40px 150px 40px;
  `}
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mobile`
    width: 100%;
    align-items: center;
  `}
  ${media.desktop`
    fit-content: 100%;
    align-items: flex-start;
  `}
`

const Title = styled(H1)`
  color: ${(props) => props.theme.colors.slate};
  ${media.mobile`
    text-align: center;
    margin-bottom: 10px;
  `}
  ${media.desktop`
    padding: 10px 0 0 0;
    margin-bottom: 30px;
    text-align: left;
  `}
`

const CardsRow = styled.div`
  width: 100%;
  display: grid;
  padding-top: 20px;
  justify-content: center;
  ${media.mobile`
    grid-template-columns: 1fr;
    grid-gap: 60px;
  `}
  ${media.tablet`
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  `}
`

const MobileCTA = styled(InternalLink)`
  width: fit-content;
  ${media.mobile`
    margin-top: 50px;
  `}
  ${media.tablet`
    margin-top: 10px;
  `}
  ${media.desktop`
    display: none;
  `}
`

const CTALink = styled(InternalLink)`
  ${media.mobile`
    display: none;
  `}
  ${media.desktop`
    display: block;
  `}
`

const RelatedArticles = ({ recommendedArticles }) => {
  return (
    <Container>
      <TitleWrapper>
        <Title>Related Articles</Title>
        <CTALink to="/">
          <SecondaryButton copy="Back to the Blog" arrow />
        </CTALink>
      </TitleWrapper>
      <CardsRow>
        {recommendedArticles.map((post) => (
          <SmallBlogCard post={post.frontmatter} key={post.frontmatter.slug} smallText />
        ))}
      </CardsRow>
      <MobileCTA to="/">
        <SecondaryButton copy="Back to the Blog" arrow />
      </MobileCTA>
    </Container>
  )
}

export default RelatedArticles
