import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import Layout from 'src/components/Layout'
import BlogPost from 'src/components/BlogPost'
import SEO from 'src/components/shared/SEO'
import { HTMLContent } from 'src/components/BlogPost/Content'

const buildCustomSchemaScript = ({ frontmatter: post }) => {
  const imagePath = post.featuredimage.childImageSharp.gatsbyImageData.images.fallback.src
  return (
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.petfinn.com/blog/articles/${post.slug}"
          },
          "headline": "${post.title}",
          "image": [
            "https://www.petfinn.com/blog${imagePath}"
          ],  
          "author": {
            "@type": "Person",
            "name": "${post.author}"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "Finn",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.petfinn.com/blog/static/0443ac05e48b8348c15673a6aac476ba/26fa5/logo-white-text.webp"
            }
          },
          "datePublished": "${post.date}"
        }
      `}
    </script>
  )
}

const BlogPostTemplate = ({ data, location }) => {
  const { tableOfContents, mainPost: post, recommended } = data

  const recommendedArticles = recommended.edges.map((edge) => edge.node)

  const customSchemaScript = buildCustomSchemaScript(post)

  const slug = `/articles/${post.frontmatter.slug}`
  const canonical = `https://www.petfinn.com${slug}`

  return (
    <>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={getImage(post.frontmatter.featuredimage)}
      >
        {customSchemaScript}
        <link rel="canonical" href={canonical} />
      </SEO>
      <Layout>
        <BlogPost
          location={location}
          post={post}
          tableOfContents={tableOfContents}
          contentComponent={HTMLContent}
          slug={slug}
          recommendedArticles={recommendedArticles}
        />
      </Layout>
    </>
  )
}

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $tag: String) {
    tableOfContents: markdownRemark(id: { eq: $id }) {
      tableOfContents
    }
    mainPost: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        draft
        includeToc
        featuredimage {
          childImageSharp {
            gatsbyImageData(width: 250, quality: 100)
          }
        }
        heroimage {
          childImageSharp {
            gatsbyImageData(width: 1400, quality: 100)
          }
        }
        heroimagemobile {
          childImageSharp {
            gatsbyImageData(width: 600, quality: 100)
          }
        }
        heroalt
        date(formatString: "MMMM DD, YYYY")
        title
        slug
        author
        description
        photocred
        tags
        ad
      }
    }
    recommended: allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { id: { ne: $id }, frontmatter: { tags: { in: [$tag] }, draft: { eq: false } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            slug
            title
            large
            templateKey
            tags
            featuredimage {
              childImageSharp {
                gatsbyImageData(width: 250, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`
