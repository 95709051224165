import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import PostAttribution from 'src/components/BlogPost/PostAttribution'
import EmailCapture from 'src/components/shared/EmailCapture'
import RelatedArticles from 'src/components/BlogPost/RelatedArticles'
import ShareCol from 'src/components/BlogPost/ShareCol'
import Comments from 'src/components/BlogPost/Comments'
import ShareRow from 'src/components/BlogPost/ShareRow'
import BannerAd from 'src/components/shared/BannerAd'
import { Headline, LargeP } from 'src/components/shared/text'
import Content from 'src/components/BlogPost/Content'
import { media, getScreenType } from 'src/utils/style-helper'

const Wrapper = styled.div`
  width: 100%;
`

const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  display: grid;
  ${media.mobile`
    padding: 40px 14px 20px 14px;
  `}
  ${media.tablet`
    padding: 60px 20px;
    grid-template-columns: 50px minmax(400px, 600px) auto;
  `}
  ${media.desktop`
    width: fit-content;
    grid-template-columns: 50px minmax(500px, 900px) 225px;
    padding: 80px 60px 80px 40px;
    margin: 0 auto;
  `}
`

const HeroImageWrapper = styled.div`
  width: 100%;

  ${media.mobile`
    height: 325px;
  `}
  ${media.tablet`
    height: 350px;
  `}
  ${media.desktop`
    height: 500px;
  `}
`

const ContentWrapper = styled.section`
  width: 100%;
  margin: 0 auto;
  ${media.desktop`
    padding-right: 20px;
  `}
`

const Main = styled.div`
  margin: 0 auto;
  ${media.mobile`
    padding: 0 10px;
  `}
  ${media.tablet`
    max-width: 415px;
    padding: 0;
  `}
  ${media.desktop`
    max-width: 680px;
  `}
`

const Description = styled(LargeP)`
  ${media.mobile`
    font-family: ${(props) => props.theme.fonts.larsseit.medium};
    font-size: 20px;
    margin: 30px auto 20px auto;
  `}
  ${media.desktop`
    font-family: ${(props) => props.theme.fonts.larsseit.regular};
    font-size: 24px;
    margin: 40px auto 20px auto;
  `}
`

const TableOfContents = styled.div`
  width: fit-content;

  background-color: ${(props) => props.theme.colors.lightText};

  font-family: ${(props) => props.theme.fonts.larsseit.medium};
  color: ${(props) => props.theme.colors.navy};

  border-radius: 6px;
  font-size: 24px;

  ${media.mobile`
    margin: 20px 0 0 0;
    padding: 20px 10px 20px 10px;
  `}
  ${media.tablet`
    margin: 20px 0;
    padding: 20px 30px 20px 20px;
  `}
  ${media.desktop`
    margin: 40px 0;
    min-width: 400px;
    padding: 30px 60px 30px 30px;
  `}
`

const TableOfContentsOlWrapper = styled.div`
  font-family: ${(props) => props.theme.fonts.larsseit.regular};
  padding: 20px 0 0 0;

  & p {
    margin: 0;
  }

  & ul {
    margin: 0 0 12px 0;
    line-height: 1.3;
    padding: 0 6px 0 28px;

    ${media.mobile`
      font-size: 18px;
    `}
    ${media.tablet`
      font-size: 20px;
    `}

    & li {
      margin: 10px 0;
    }

    & strong {
      font-weight: 400;
    }

    & li a {
      color: ${(props) => props.theme.colors.navy};
      text-decoration: underline;
    }

    & li a:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.colors.orange};
    }

    & ul {
      ${media.mobile`
        font-size: 16px;
      `}
      ${media.tablet`
        font-size: 18px;
      `}
      
      margin: 9px 0 10px 0;

      & li {
        font-size: 16px; 
        margin-top: 12px;
      }
    }
  }
`

const RightCol = styled.div`
  ${media.mobile`
    display: none
  `}
  ${media.tablet`
    display: block
  `}
`

const MobileOnly = styled.div`
  margin: 20px 0;
  ${media.tablet`
    display: none;
  `}
`

const InlineAd = styled(MobileOnly)`
  padding: 20px 9px;
  margin: 40px 0;
  border-top: 1px solid ${(props) => props.theme.colors.lightText};
  border-bottom: 1px solid ${(props) => props.theme.colors.lightText};
  padding: 20px 9px;
`

const BlogPost = (props) => {
  const { location, post, tableOfContents, contentComponent, slug, recommendedArticles } = props

  const {
    id,
    html: content,
    frontmatter: {
      includeToc,
      description,
      tags,
      title,
      date,
      author,
      heroimage,
      heroimagemobile,
      heroalt,
      ad,
      photocred,
    },
  } = post

  const tocContent = tableOfContents?.tableOfContents || ''

  const [editedToc, setEditedToc] = useState(tocContent)

  const PostContent = contentComponent || Content

  const displayedHeroImg =
    heroimagemobile && getScreenType() === 'mobile' ? heroimagemobile : heroimage

  let pathname = ''
  if (typeof window !== 'undefined') pathname = `${window.location.pathname}#`

  useEffect(() => {
    if (includeToc) {
      let urlRegex = new RegExp(/\/articles.*#/g)
      if (pathname.includes('/blog')) urlRegex = new RegExp(/\/blog\/articles.*#/g)
      const regexedToc = tocContent.replaceAll(urlRegex, pathname)
      setEditedToc(regexedToc)
    }
  }, [pathname, includeToc, tocContent])

  return (
    <Wrapper>
      <HeroImageWrapper>
        <GatsbyImage
          image={getImage(displayedHeroImg)}
          alt={heroalt}
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ objectFit: 'cover' }}
        />
      </HeroImageWrapper>
      <Container>
        <RightCol>
          <ShareCol title={title} slug={slug} />
        </RightCol>
        <ContentWrapper>
          <Main>
            <Headline>{title}</Headline>
            <MobileOnly>
              <PostAttribution tags={tags} author={author} date={date} photoCred={photocred} />
            </MobileOnly>
            <Description>{description}</Description>

            {includeToc && (
              <TableOfContents>
                Table of Contents:
                <TableOfContentsOlWrapper dangerouslySetInnerHTML={{ __html: editedToc }} />
              </TableOfContents>
            )}

            <InlineAd>
              <BannerAd />
            </InlineAd>
            <PostContent content={content} />
            <ShareRow title={title} slug={slug} />

            <Comments id={id} location={location} />
          </Main>
          <MobileOnly>
            <BannerAd />
          </MobileOnly>
        </ContentWrapper>
        <RightCol>
          <PostAttribution tags={tags} author={author} date={date} photoCred={photocred} />
          <BannerAd ad={ad} />
        </RightCol>
      </Container>
      {recommendedArticles.length > 0 && (
        <RelatedArticles recommendedArticles={recommendedArticles} />
      )}
      <EmailCapture />
    </Wrapper>
  )
}

BlogPost.propTypes = {
  post: PropTypes.object.isRequired,
  tableOfContents: PropTypes.object.isRequired,
  contentComponent: PropTypes.node.isRequired,
  slug: PropTypes.string.isRequired,
  recommendedArticles: PropTypes.array.isRequired,
}

export default BlogPost
