import React from 'react'
import styled from 'styled-components'
import StickyBox from 'react-sticky-box'
import { StaticQuery, graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { ExternalLink } from 'src/components/shared/text'
import { media, getScreenType } from 'src/utils/style-helper'

const PaddedWrapper = styled(StickyBox)`
  height: fit-content;
  ${media.mobile`
    width: 100%;
  `}
  ${media.tablet`
    border: none;
    padding: 5vh 0 0 0;
  `}
  ${media.desktop`
    width: 225px;
  `}
`

const BannerAd = ({ ad }) => (
  <StaticQuery
    query={BannerAdQuery}
    render={(data) => {
      const adMap = data.allMarkdownRemark.edges.reduce((map, { node }) => {
        map[kebabCase(node.frontmatter.product)] = {
          image: getImage(node.frontmatter.image),
          imageMobile: getImage(node.frontmatter.imageMobile),
          url: node.frontmatter.url,
          productName: node.frontmatter.product,
        }
        return map
      }, {})

      const advertisement = adMap[ad] || adMap['global']
      if (!advertisement) return null

      const isMobile = getScreenType() === 'mobile'
      const displayedImage = isMobile ? advertisement.imageMobile : advertisement.image

      return (
        <ExternalLink to={advertisement.url} name="advertisement link">
          <PaddedWrapper>
            <GatsbyImage
              image={displayedImage}
              alt={`Advertisement for ${advertisement.productName}`}
              style={{ width: '100%', height: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </PaddedWrapper>
        </ExternalLink>
      )
    }}
  />
)

const BannerAdQuery = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { advertisement: { eq: true } } }) {
      edges {
        node {
          frontmatter {
            product
            url
            image {
              childImageSharp {
                gatsbyImageData(width: 225)
              }
            }
            imageMobile {
              childImageSharp {
                gatsbyImageData(width: 200)
              }
            }
          }
        }
      }
    }
  }
`

export default BannerAd
