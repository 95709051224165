import React from 'react'
import styled from 'styled-components'
import { Envelope } from '@styled-icons/boxicons-regular'
import { Facebook, Twitter } from '@styled-icons/boxicons-logos'
import StickyBox from 'react-sticky-box'

import {
  shareViaFacebook,
  shareViaTwitter,
  sharableEmailUrl,
} from 'src/utils/share-on-social'
import { ExternalLink } from 'src/components/shared/text'
import { media } from 'src/utils/style-helper'

const Container = styled(StickyBox)`
  color: ${(props) => props.theme.colors.slate};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.tablet`
    padding: 46px 0 180px 0;
    margin: -46px 0 0 0;
  `}
  ${media.desktop`
    padding: 30px 0 180px 0;
    margin: -30px 0 0 0;
  `}
`

const Icon = styled.div`
  cursor: pointer;
  &:hover {
    & * {
      fill: ${(props) => props.theme.colors.orange};
    }
  }
  ${media.mobile`
    display: none;
  `}
  ${media.tablet`
    display: block;
    height: 36px;
    width: 36px;
    margin: 6px 0;
    &:last-of-type {
      height: 24px;
      width: 24px;
    }
  `}
  ${media.desktop`
    height: 40px;
    width: 40px;
    margin: 12px 0;
    &:last-of-type {
      height: 30px;
      width: 30px;
    }
  `}
`

const ShareCol = ({ title, slug }) => {
  const emailHref = sharableEmailUrl(title, slug)

  return (
    <Container>
      <Icon onClick={() => shareViaFacebook(title, slug)} name="Share on Facebook">
        <Facebook />
      </Icon>
      <Icon onClick={() => shareViaTwitter(title, slug)} name="Share on Twitter">
        <Twitter />
      </Icon>
      <ExternalLink to={emailHref} name="Share by email">
        <Icon>
          <Envelope />
        </Icon>
      </ExternalLink>
    </Container>
  )
}

export default ShareCol
