import React from 'react'
import styled from 'styled-components'
import { Envelope } from '@styled-icons/boxicons-regular'
import { Facebook, Twitter } from '@styled-icons/boxicons-logos'

import { MonoP } from 'src/components/shared/text'
import {
  shareViaFacebook,
  shareViaTwitter,
  sharableEmailUrl,
} from 'src/utils/share-on-social'
import { ExternalLink } from 'src/components/shared/text'
import { media } from 'src/utils/style-helper'

const Container = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.slate};
  display: flex;
  align-items: center;
  & p {
    font-size: 15px;
    line-height: 1;
    padding-top: 5px;
  }
  ${media.mobile`
    margin: 50px 0 50px 0;
  `}
  ${media.tablet`
    margin: 50px 0 100px 0;
  `}
`

const Line = styled.div`
  height: 1px;
  background-color: #d9d9d9;
  margin: 0 0 0 12px;
  width: 100px;
`

const Icon = styled.div`
  cursor: pointer;
  &:hover {
    & * {
      fill: ${(props) => props.theme.colors.orange};
    }
  }
  height: 30px;
  width: 30px;
  margin: 0 12px;
  &:last-of-type {
    height: 24px;
    width: 24px;
  }
`

const ShareRow = ({ title, slug }) => {
  const emailHref = sharableEmailUrl(title, slug)
  return (
    <Container>
      <MonoP>SHARE</MonoP>
      <Line />
      <Icon onClick={() => shareViaFacebook(title, slug)} name="share on Facebook">
        <Facebook />
      </Icon>
      <Icon onClick={() => shareViaTwitter(title, slug)} name="share on Twitter">
        <Twitter />
      </Icon>
      <ExternalLink to={emailHref} name="share by email">
        <Icon>
          <Envelope />
        </Icon>
      </ExternalLink>
    </Container>
  )
}

export default ShareRow
