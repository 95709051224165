const getHostName = () => {
  const host = window && window.location && window.location.host
  const protocol = window.location.protocol
  return `${protocol}//${host}`
}

const openWindow = (url, params) => {
  const queryString = Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&')

  const fullUrl = `${url}?${queryString}`

  window.open(
    fullUrl,
    null,
    'width=600, height=400, location=0, menubar=0, resizeable=0, scrollbars=0, status=0, titlebar=0, toolbar=0'
  )
}
export const shareViaTwitter = (slug) => {
  openWindow('https://twitter.com/share', {
    url: `${getHostName()}/?type=twitter`,
    text: `Check this article out from Finn's blog, Sidekick: ${slug}`,
  })
}

export const shareViaFacebook = (title, slug) => {
  openWindow('https://www.facebook.com/sharer/sharer.php', {
    u: `${getHostName()}/?type=facebook`,
    'og:title': `You should checkout out this article: "${title}"`,
    'og:description': `Check this article out from Finn's blog, Sidekick: ${slug}`,
  })
}

export const sharableEmailUrl = (title, slug) => {
  const subject = `You should checkout out this article: "${title}"`
  const body = `Check this article out from Finn's blog, Sidekick: https://blog.petfinn.com${slug}`
  return `mailto:?subject=${subject}&body=${body}`
}
