import React from 'react'
import styled from 'styled-components'
import { Disqus } from 'gatsby-plugin-disqus'

import { H4 } from 'src/components/shared/text'

const Container = styled.div`
  width: 100%;
  border-top: ${(props) => props.theme.colors.navy} solid 3px;
  padding: 16px 0 45px 0;
  max-width: 550px;
`

const DisquisWrapper = styled.div`
  padding: 20px 6px 0 6px;
`

const Header = styled(H4)`
  color: ${(props) => props.theme.colors.navy};
`

const Comments = ({ id, location }) => {
  return (
    <Container>
      <Header as="p">Comments:</Header>

      <DisquisWrapper>
        <Disqus config={{ url: location.href, identifier: id }} />
      </DisquisWrapper>
    </Container>
  )
}

export default Comments
