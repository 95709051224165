import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { kebabCase } from 'lodash'

import { MonoP, InternalLink } from 'src/components/shared/text'
import { media } from 'src/utils/style-helper'

const MetaData = styled(MonoP)`
  color: ${(props) => props.theme.colors.slate};
  margin-bottom: 12px;
`

const NavySpan = styled.span`
  color: ${(props) => props.theme.colors.navy};
`

const Link = styled(InternalLink)`
  color: ${(props) => props.theme.colors.navy};
`

const MetaGroup = styled.div`
  width: fit-content;
  ${media.mobile`
    width: 100%;
    margin: 0;
    padding: 16px 0 6px 0;
    border-top: 1px solid ${(props) => props.theme.colors.lightText};
    border-bottom: 1px solid ${(props) => props.theme.colors.lightText};
  `}
  ${media.tablet`
    margin: 6px 0 0 0;
    width: fit-content;
    padding: 0;
    border: none;
  `}
  ${media.desktop`
    margin: 20px 12px;
    max-width: 200px;
  `}
`

const PostAttribution = ({ tags, date, author, photoCred }) => {
  return (
    <MetaGroup>
      {tags && tags.length
        ? tags.map((tag) => (
            <MetaData key={tag}>
              Posted In <Link to={`/categories/${kebabCase(tag)}`}>{tag}</Link>
            </MetaData>
          ))
        : null}
      <MetaData>{date}</MetaData>
      <MetaData>
        BY <NavySpan>{author}</NavySpan>
      </MetaData>
      {photoCred && <MetaData>{photoCred}</MetaData>}
    </MetaGroup>
  )
}

PostAttribution.propTypes = {
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  photoCred: PropTypes.string,
}

PostAttribution.defaultProps = {
  photoCred: '',
}

export default PostAttribution
